const parsePath = path => path
  .replace(/\[|\]\.?/g, '.')
  .split('.')
  .filter(s => s)

const get = (obj, path) => parsePath(path).reduce((acc, val) => acc && acc[val], obj)


const isMobile = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
    let isMatched = false
    if (typeof window !== 'undefined') {
      isMatched = navigator.userAgent.match(toMatchItem)
    }
    return isMatched
  });
}

const Util = {
  jsonGet: get,
  isMobile
}

export default Util